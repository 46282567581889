




































































import { StromeeLogGroup, StromeePlusLogGroup } from '../../typings';
import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { SettingsModule } from '../../plugins/store';

const settingsStore: SettingsModule = getModule(SettingsModule);

@Component
export default class LogListView extends Vue {
  private readonly stromeePlusItems: Record<StromeePlusLogGroup, string> = {
    [StromeePlusLogGroup.DEVICES]: '$vuetify.logs.DEVICE_LOGS',
    [StromeePlusLogGroup.PROVISIONING]: '$vuetify.logs.PROVISIONING_LOGS',
    [StromeePlusLogGroup.IOT]: '$vuetify.logs.IOT_LOGS',
    [StromeePlusLogGroup.API]: '$vuetify.logs.API_LOGS',
    [StromeePlusLogGroup.FLAG]: '$vuetify.logs.FLAG_LOGS',
    [StromeePlusLogGroup.HISTORY]: '$vuetify.logs.HISTORY_LOGS',
    [StromeePlusLogGroup.CONNECTION]: '$vuetify.logs.CONNECTION_LOGS'
  };
  private readonly stromeeItems: Record<StromeeLogGroup, string> = {
    [StromeeLogGroup.CUSTOMER_API]: '$vuetify.logs.CUSTOMER_API_LOGS',
    [StromeeLogGroup.SIGNUP]: '$vuetify.logs.SIGNUP_LOGS',
    [StromeeLogGroup.MIGRATION]: '$vuetify.logs.MIGRATION_LOGS',
    [StromeeLogGroup.EMAIL]: '$vuetify.logs.EMAIL_LOGS'
  };

  private get showConsole(): boolean {
    return settingsStore.console;
  }
}
